/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/dropzone.css');
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
//require('../js/functions.js');

const $ = require('jquery');
const SweetAlert = require('sweetalert2');

(function(window) {
    /* class CrmSymfonyApp {}
    /**
     * A "private" object
     */
    /*class Helper {

    } */

    class Alert {
        constructor() {

        }

        fire(params) {
            let alert = SweetAlert.fire({
                title: params.title,
                text: params.text,
                html: params.html,
                icon: params.icon,
                showCancelButton: params.showCancelButton,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: params.confirmButtonText??'Aceptar',
                cancelButtonText: params.cancelButtonText??'Cancelar'
            })
            if (params.callback) {
                alert.then((result) => {
                    if (result.isConfirmed) {
                        params.callback();
                    }
                })
            }
        }

        confirm(params) {
            this.fire({
                icon: 'warning',
                title: params.title,
                text: params.text,
                showCancelButton: true,
                callback: params.callback,

            })
        }

        success(params) {
            this.fire({
                title: params.title,
                text: params.text,
                icon: 'success',
                showCancelButton: false
            })
        }

        error(params) {
            this.fire({
                title: params.title,
                text: params.text,
                icon: 'error',
                showCancelButton: false
            })
        }

    }


    //window.CrmSymfonyApp = CrmSymfonyApp;
    window.Alert = Alert;
})(window);